/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    pre: "pre",
    code: "code",
    p: "p",
    hr: "hr",
    h1: "h1",
    a: "a",
    div: "div",
    h3: "h3",
    h2: "h2",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack spacing=\"3\" alignItems=\"baseline\">\n\t<Headline size=\"small\">Invoice #123457</Headline>\n\t<Tag>Payment Needed</Tag>\n\t<Tag>Work Todo</Tag>\n\t<Tag>Has Dog</Tag>\n</Stack>\n")), "\n", React.createElement(_components.p, null, "The Stack component is built off CSS flex properties. The usage examples here reflect many of these properties."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "vertical-alignment",
    style: {
      position: "relative"
    }
  }, "Vertical Alignment", React.createElement(_components.a, {
    href: "#vertical-alignment",
    "aria-label": "vertical alignment permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A stack can be vertically aligned via the ", React.createElement(_components.code, null, "alignItems"), " properties via CSS flex. Individual stack items can also be vertically aligned."), "\n", React.createElement(_components.h3, {
    id: "top-flex-start",
    style: {
      position: "relative"
    }
  }, "Top (Flex Start)", React.createElement(_components.a, {
    href: "#top-flex-start",
    "aria-label": "top flex start permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-white\">\n    <Stack alignItems='flex-start' spacing='4'>\n        <Stack.Item><Button small primary>Small Button</Button></Stack.Item>\n        <Stack.Item><BodyText size=\"xsmall\">Text</BodyText></Stack.Item>\n        <Stack.Item><Avatar name='Rose Tico' size='l' color='red' /></Stack.Item>\n        <Stack.Item><Tag onClose={() => {}}>A closable tag</Tag></Stack.Item>\n    </Stack>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "center",
    style: {
      position: "relative"
    }
  }, "Center", React.createElement(_components.a, {
    href: "#center",
    "aria-label": "center permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-white\">\n\t<Stack alignItems='center' spacing='4'>\n\t\t<Stack.Item><Button small primary>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText size=\"xsmall\">Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='red' /></Stack.Item>\n\t\t<Stack.Item><Tag onClose={() => {}}>A closable tag</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "bottom-flex-end",
    style: {
      position: "relative"
    }
  }, "Bottom (Flex End)", React.createElement(_components.a, {
    href: "#bottom-flex-end",
    "aria-label": "bottom flex end permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-white\">\n\t<Stack alignItems='flex-end' spacing='4'>\n\t\t<Stack.Item><Button small primary>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText size=\"xsmall\">Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='red' /></Stack.Item>\n\t\t<Stack.Item><Tag onClose={() => {}}>A closable tag</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "horizontal-alignment",
    style: {
      position: "relative"
    }
  }, "Horizontal Alignment", React.createElement(_components.a, {
    href: "#horizontal-alignment",
    "aria-label": "horizontal alignment permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A stack can be horizontally aligned via the ", React.createElement(_components.code, null, "justifyContent"), " properties via CSS flex."), "\n", React.createElement(_components.h3, {
    id: "left-flex-start",
    style: {
      position: "relative"
    }
  }, "Left (Flex Start)", React.createElement(_components.a, {
    href: "#left-flex-start",
    "aria-label": "left flex start permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-white\">\n\t<Stack alignItems='center' justifyContent='flex-start' spacing='4'>\n\t\t<Stack.Item><Button small primary>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText size=\"xsmall\">Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='red' /></Stack.Item>\n\t\t<Stack.Item><Tag onClose={() => {}}>A closable tag</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "center-1",
    style: {
      position: "relative"
    }
  }, "Center", React.createElement(_components.a, {
    href: "#center-1",
    "aria-label": "center 1 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-white\">\n\t<Stack alignItems='center' justifyContent='center' spacing='4'>\n\t\t<Stack.Item><Button small primary>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText size=\"xsmall\">Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='red' /></Stack.Item>\n\t\t<Stack.Item><Tag onClose={() => {}}>A closable tag</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "right-flex-end",
    style: {
      position: "relative"
    }
  }, "Right (Flex End)", React.createElement(_components.a, {
    href: "#right-flex-end",
    "aria-label": "right flex end permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-white\">\n\t<Stack alignItems='center' justifyContent='flex-end' spacing='4'>\n\t\t<Stack.Item><Button small primary>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText size=\"xsmall\">Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='red' /></Stack.Item>\n\t\t<Stack.Item><Tag onClose={() => {}}>A closable tag</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "space-between",
    style: {
      position: "relative"
    }
  }, "Space Between", React.createElement(_components.a, {
    href: "#space-between",
    "aria-label": "space between permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-white\">\n\t<Stack alignItems='center' justifyContent='space-between' spacing='4'>\n\t\t<Stack.Item><Button small primary>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText size=\"xsmall\">Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='red' /></Stack.Item>\n\t\t<Stack.Item><Tag onClose={() => {}}>A closable tag</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "space-around",
    style: {
      position: "relative"
    }
  }, "Space Around", React.createElement(_components.a, {
    href: "#space-around",
    "aria-label": "space around permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-white\">\n\t<Stack alignItems='center' justifyContent='space-around' spacing='4'>\n\t\t<Stack.Item><Button small primary>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText size=\"xsmall\">Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='red' /></Stack.Item>\n\t\t<Stack.Item><Tag onClose={() => {}}>A closable tag</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "space-evenly",
    style: {
      position: "relative"
    }
  }, "Space Evenly", React.createElement(_components.a, {
    href: "#space-evenly",
    "aria-label": "space evenly permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-white\">\n\t<Stack alignItems='center' justifyContent='space-evenly' spacing='4'>\n\t\t<Stack.Item><Button small primary>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText size=\"xsmall\">Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='red' /></Stack.Item>\n\t\t<Stack.Item><Tag onClose={() => {}}>A closable tag</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "stack-direction",
    style: {
      position: "relative"
    }
  }, "Stack Direction", React.createElement(_components.a, {
    href: "#stack-direction",
    "aria-label": "stack direction permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The direction of a stack can be in a row or column. Items can also be reversed."), "\n", React.createElement(_components.h3, {
    id: "row",
    style: {
      position: "relative"
    }
  }, "Row", React.createElement(_components.a, {
    href: "#row",
    "aria-label": "row permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<style>{`\n\t.box {\n\t\tbackground: #9013fe;\n\t\tcolor: white;\n\t\tpadding: 16px;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tmin-height: 40px;\n\t\tline-height: 2;\n\t\twidth: 50px;\n\t}\n\t.box:nth-of-type(even) { background: #007e7e; }\n\t`}</style>\n\t<Stack direction='row'>\n\t\t<div className='box'>1</div>\n\t\t<div className='box'>2</div>\n\t\t<div className='box'>3</div>\n\t\t<div className='box'>4</div>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "row-reverse",
    style: {
      position: "relative"
    }
  }, "Row Reverse", React.createElement(_components.a, {
    href: "#row-reverse",
    "aria-label": "row reverse permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<style>{`\n\t.box {\n\t\tbackground: #9013fe;\n\t\tcolor: white;\n\t\tpadding: 16px;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tmin-height: 40px;\n\t\tline-height: 2;\n\t\twidth: 50px;\n\t}\n\t.box:nth-of-type(even) { background: #007e7e; }\n\t`}</style>\n\t<Stack direction='row-reverse'>\n\t\t<div className='box'>1</div>\n\t\t<div className='box'>2</div>\n\t\t<div className='box'>3</div>\n\t\t<div className='box'>4</div>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "column",
    style: {
      position: "relative"
    }
  }, "Column", React.createElement(_components.a, {
    href: "#column",
    "aria-label": "column permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<style>{`\n\t.box {\n\t\tbackground: #9013fe;\n\t\tcolor: white;\n\t\tpadding: 16px;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tmin-height: 40px;\n\t\tline-height: 2;\n\t\twidth: 50px;\n\t}\n\t.box:nth-of-type(even) { background: #007e7e; }\n\t`}</style>\n\t<Stack direction='column'>\n\t\t<div className='box'>1</div>\n\t\t<div className='box'>2</div>\n\t\t<div className='box'>3</div>\n\t\t<div className='box'>4</div>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "column-reverse",
    style: {
      position: "relative"
    }
  }, "Column Reverse", React.createElement(_components.a, {
    href: "#column-reverse",
    "aria-label": "column reverse permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<style>{`\n\t.box {\n\t\tbackground: #9013fe;\n\t\tcolor: white;\n\t\tpadding: 16px;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tmin-height: 40px;\n\t\tline-height: 2;\n\t\twidth: 50px;\n\t}\n\t.box:nth-of-type(even) { background: #007e7e; }\n\t`}</style>\n\t<Stack direction='column-reverse'>\n\t\t<div className='box'>1</div>\n\t\t<div className='box'>2</div>\n\t\t<div className='box'>3</div>\n\t\t<div className='box'>4</div>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "item-spacing",
    style: {
      position: "relative"
    }
  }, "Item Spacing", React.createElement(_components.a, {
    href: "#item-spacing",
    "aria-label": "item spacing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Stack Items can be evenly spaced out, based on our ", React.createElement(_components.a, {
    href: "/foundations/spacing"
  }, "spacing scale"), ". This is the ", React.createElement(_components.code, null, "spacing"), " property in Stack, ranging from ", React.createElement(_components.code, null, "0"), " to ", React.createElement(_components.code, null, "5"), "."), "\n", React.createElement(_components.h2, {
    id: "horizontal-spacing",
    style: {
      position: "relative"
    }
  }, "Horizontal Spacing", React.createElement(_components.a, {
    href: "#horizontal-spacing",
    "aria-label": "horizontal spacing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When the Stack's direction is ", React.createElement(_components.code, null, "row"), " (the default behavior), the spacing property is applied horizontally."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<div className=\"bg-white\">\n\t<Stack alignItems='center' spacing='0'>\n\t\t<Stack.Item><Avatar name='Igor Zoe' size='m' color='green' /></Stack.Item>\n\t\t<Stack.Item><Tag>Person</Tag></Stack.Item>\n\t\t<Stack.Item><Tag>High Priority</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<div className=\"bg-white\">\n\t<Stack alignItems='center' spacing='1'>\n\t\t<Stack.Item><Avatar name='Igor Zoe' size='m' color='green' /></Stack.Item>\n\t\t<Stack.Item><Tag>Person</Tag></Stack.Item>\n\t\t<Stack.Item><Tag>High Priority</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<div className=\"bg-white\">\n\t<Stack alignItems='center' spacing='2'>\n\t\t<Stack.Item><Avatar name='Igor Zoe' size='m' color='green' /></Stack.Item>\n\t\t<Stack.Item><Tag>Person</Tag></Stack.Item>\n\t\t<Stack.Item><Tag>High Priority</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<div className=\"bg-white\">\n\t<Stack alignItems='center' spacing='3'>\n\t\t<Stack.Item><Avatar name='Igor Zoe' size='m' color='green' /></Stack.Item>\n\t\t<Stack.Item><Tag>Person</Tag></Stack.Item>\n\t\t<Stack.Item><Tag>High Priority</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<div className=\"bg-white\">\n\t<Stack alignItems='center' spacing='4'>\n\t\t<Stack.Item><Avatar name='Igor Zoe' size='m' color='green' /></Stack.Item>\n\t\t<Stack.Item><Tag>Person</Tag></Stack.Item>\n\t\t<Stack.Item><Tag>High Priority</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<div className=\"bg-white\">\n\t<Stack alignItems='center' spacing='5'>\n\t\t<Stack.Item><Avatar name='Igor Zoe' size='m' color='green' /></Stack.Item>\n\t\t<Stack.Item><Tag>Person</Tag></Stack.Item>\n\t\t<Stack.Item><Tag>High Priority</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h2, {
    id: "vertical-spacing",
    style: {
      position: "relative"
    }
  }, "Vertical Spacing", React.createElement(_components.a, {
    href: "#vertical-spacing",
    "aria-label": "vertical spacing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When the Stack's direction is ", React.createElement(_components.code, null, "column"), ", the spacing property is applied vertically."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<div className=\"bg-white\">\n\t<Stack direction='column' alignItems='center' justifyContent='center' spacing='0'>\n\t\t<Stack.Item><Avatar name='Igor Zoe' size='m' color='green' /></Stack.Item>\n\t\t<Stack.Item><Tag>Person</Tag></Stack.Item>\n\t\t<Stack.Item><Tag>High Priority</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<div className=\"bg-white\">\n\t<Stack direction='column' alignItems='center' justifyContent='center' spacing='1'>\n\t\t<Stack.Item><Avatar name='Igor Zoe' size='m' color='green' /></Stack.Item>\n\t\t<Stack.Item><Tag>Person</Tag></Stack.Item>\n\t\t<Stack.Item><Tag>High Priority</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<div className=\"bg-white\">\n\t<Stack direction='column' alignItems='center' justifyContent='center' spacing='2'>\n\t\t<Stack.Item><Avatar name='Igor Zoe' size='m' color='green' /></Stack.Item>\n\t\t<Stack.Item><Tag>Person</Tag></Stack.Item>\n\t\t<Stack.Item><Tag>High Priority</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<div className=\"bg-white\">\n\t<Stack direction='column' alignItems='center' justifyContent='center' spacing='3'>\n\t\t<Stack.Item><Avatar name='Igor Zoe' size='m' color='green' /></Stack.Item>\n\t\t<Stack.Item><Tag>Person</Tag></Stack.Item>\n\t\t<Stack.Item><Tag>High Priority</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<div className=\"bg-white\">\n\t<Stack direction='column' alignItems='center' justifyContent='center' spacing='4'>\n\t\t<Stack.Item><Avatar name='Igor Zoe' size='m' color='green' /></Stack.Item>\n\t\t<Stack.Item><Tag>Person</Tag></Stack.Item>\n\t\t<Stack.Item><Tag>High Priority</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "span: 6\n---\n<div className=\"bg-white\">\n\t<Stack direction='column' alignItems='center' justifyContent='center' spacing='5'>\n\t\t<Stack.Item><Avatar name='Igor Zoe' size='m' color='green' /></Stack.Item>\n\t\t<Stack.Item><Tag>Person</Tag></Stack.Item>\n\t\t<Stack.Item><Tag>High Priority</Tag></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "stack-wrapping",
    style: {
      position: "relative"
    }
  }, "Stack Wrapping", React.createElement(_components.a, {
    href: "#stack-wrapping",
    "aria-label": "stack wrapping permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Stack items can utilize flex's ", React.createElement(_components.code, null, "wrap"), " functionality to wrap Stack items, prevent wrapping, or wrap reverse items."), "\n", React.createElement(_components.h3, {
    id: "nowrap-stack-items",
    style: {
      position: "relative"
    }
  }, "Nowrap Stack Items", React.createElement(_components.a, {
    href: "#nowrap-stack-items",
    "aria-label": "nowrap stack items permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-white\">\n\t<Stack wrap=\"nowrap\" spacing=\"2\" alignItems=\"center\">\n\t\t<Stack.Item><Button small>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText>Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='red' /></Stack.Item>\n\t\t<Stack.Item><Button large>Big Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText>Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Button small primary>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText>Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='green' /></Stack.Item>\n\t\t<Stack.Item><Button small>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText>Text</BodyText></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "wrapped-stack-items",
    style: {
      position: "relative"
    }
  }, "Wrapped Stack Items", React.createElement(_components.a, {
    href: "#wrapped-stack-items",
    "aria-label": "wrapped stack items permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-white\">\n\t<Stack wrap=\"wrap\" spacing=\"2\" alignItems=\"center\">\n\t\t<Stack.Item><Button small>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText>Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='red' /></Stack.Item>\n\t\t<Stack.Item><Button large>Big Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText>Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Button small primary>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText>Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='green' /></Stack.Item>\n\t\t<Stack.Item><Button small>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText>Text</BodyText></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h3, {
    id: "reverse-wrapped-stack-items",
    style: {
      position: "relative"
    }
  }, "Reverse Wrapped Stack Items", React.createElement(_components.a, {
    href: "#reverse-wrapped-stack-items",
    "aria-label": "reverse wrapped stack items permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-white\">\n\t<Stack wrap=\"wrap-reverse\" spacing=\"2\" alignItems=\"center\">\n\t\t<Stack.Item><Button small>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText>Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='red' /></Stack.Item>\n\t\t<Stack.Item><Button large>Big Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText>Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Button small primary>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText>Text</BodyText></Stack.Item>\n\t\t<Stack.Item><Avatar name='Rose Tico' size='l' color='green' /></Stack.Item>\n\t\t<Stack.Item><Button small>Small Button</Button></Stack.Item>\n\t\t<Stack.Item><BodyText>Text</BodyText></Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "stack-items",
    style: {
      position: "relative"
    }
  }, "Stack Items", React.createElement(_components.a, {
    href: "#stack-items",
    "aria-label": "stack items permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Individual items in a stack can be altered. An optional ", React.createElement(_components.code, null, "<Stack.Item>"), " can be wrapped around an item, and properties applied to it as needed."), "\n", React.createElement(_components.h2, {
    id: "fill-grow",
    style: {
      position: "relative"
    }
  }, "Fill (Grow)", React.createElement(_components.a, {
    href: "#fill-grow",
    "aria-label": "fill grow permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "An individual item can be given the ", React.createElement(_components.code, null, "fill"), " value (or use ", React.createElement(_components.code, null, "grow"), " for more nuanced control) to occupy any free space in a container."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<Stack spacing={3} alignItems='baseline'>\n\t\t<Stack.Item fill>\n\t\t\t<Headline size=\"small\">Invoice #123456</Headline>\n\t\t</Stack.Item>\n\t\t<Tag>Payment Needed</Tag>\n\t\t<Tag>Work Todo</Tag>\n\t\t<Tag>Has Dog</Tag>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h2, {
    id: "item-vertical-alignment",
    style: {
      position: "relative"
    }
  }, "Item Vertical Alignment", React.createElement(_components.a, {
    href: "#item-vertical-alignment",
    "aria-label": "item vertical alignment permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-white\" style={{ height: '250px' }}>\n\t<Stack style={{height: '100%'}} justifyContent='space-between'>\n\t\t<Stack.Item alignSelf='flex-start'>\n\t\t\t<Button>Top Aligned Item</Button>\n\t\t</Stack.Item>\n\t\t<Stack.Item alignSelf='flex-end'>\n\t\t\t<Button>Bottom Aligned Item</Button>\n\t\t</Stack.Item>\n\t\t<Stack.Item alignSelf='center' >\n\t\t\t<Button>Center Aligned Item</Button>\n\t\t</Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h2, {
    id: "order",
    style: {
      position: "relative"
    }
  }, "Order", React.createElement(_components.a, {
    href: "#order",
    "aria-label": "order permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Individual items can be reordered."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<style>{`\n\t.box {\n\t\tbackground: #9013fe;\n\t\tcolor: white;\n\t\tpadding: 16px;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tmin-height: 40px;\n\t\tline-height: 2;\n\t}\n\t.box:nth-of-type(even) { background: #007e7e; }\n\t`}</style>\n\t<Stack>\n\t\t<Stack.Item order={3}>\n\t\t\t<div className='box'>1</div>\n\t\t</Stack.Item>\n\t\t<Stack.Item order={2}>\n\t\t\t<div className='box'>2</div>\n\t\t</Stack.Item>\n\t\t<Stack.Item order={1}>\n\t\t\t<div className='box'>3</div>\n\t\t</Stack.Item>\n\t\t<Stack.Item order={5}>\n\t\t\t<div className='box'>4</div>\n\t\t</Stack.Item>\n\t\t<Stack.Item order={4}>\n\t\t\t<div className='box'>5</div>\n\t\t</Stack.Item>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "using-stack-in-other-components",
    style: {
      position: "relative"
    }
  }, "Using Stack in other Components", React.createElement(_components.a, {
    href: "#using-stack-in-other-components",
    "aria-label": "using stack in other components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "One of the powerful features of Stack is its ability to work with other Anvil components. It can be useful in creating more complex layouts within other UI elements"), "\n", React.createElement(_components.h2, {
    id: "card-row-example",
    style: {
      position: "relative"
    }
  }, "Card Row Example", React.createElement(_components.a, {
    href: "#card-row-example",
    "aria-label": "card row example permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Card thin>\n\t<Stack alignItems='center' spacing={2}>\n\t\t<Checkbox className=\"m-x-1\" />\n\t\t<Stack.Item fill><BodyText bold>Default Booking Percentages</BodyText></Stack.Item>\n\t\t<TagGroup>\n\t\t\t<Tag>On Site</Tag>\n\t\t\t<Tag>Management</Tag>\n\t\t</TagGroup>\n\t\t<Stack className=\"p-l-4\" spacing={2}>\n\t\t\t<Button fill='subtle' size='xsmall' iconName=\"edit\" />\n\t\t\t<Button fill='subtle' size='xsmall' iconName=\"delete\" />\n\t\t</Stack>\n\t</Stack>\n</Card>\n")), "\n", React.createElement(_components.h2, {
    id: "tabs",
    style: {
      position: "relative"
    }
  }, "Tabs", React.createElement(_components.a, {
    href: "#tabs",
    "aria-label": "tabs permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<TabGroup verticallyDivided>\n\t<Tab active>\n\t\t<Stack justifyContent=\"space-around\" className=\"w-100\" spacing=\"5\" alignItems=\"center\">\n\t\t\t<Stack.Item fill>\n\t\t\t\t<Stack alignItems=\"center\" spacing=\"1\">\n\t\t\t\t\t<Icon name=\"person\" />\n\t\t\t\t\t<BodyText>Jane Doe</BodyText>\n\t\t\t\t</Stack>\n\t\t\t</Stack.Item>\n\t\t\t<BodyText size=\"small\">01:25</BodyText>\n\t\t</Stack>\n\t</Tab>\n\t<Tab>\n\t\t<Stack justifyContent=\"space-around\" className=\"w-100\" spacing=\"5\" alignItems=\"center\">\n\t\t\t<Stack.Item fill>\n\t\t\t\t<Stack alignItems=\"center\" spacing=\"1\">\n\t\t\t\t\t<Icon name=\"people\" />\n\t\t\t\t\t<BodyText>Company Name</BodyText>\n\t\t\t\t</Stack>\n\t\t\t</Stack.Item>\n\t\t\t<BodyText size=\"small\">06:33</BodyText>\n\t\t</Stack>\n\t</Tab>\n\t<Tab>\n\t\t<Stack justifyContent=\"space-around\" className=\"w-100\" spacing=\"5\" alignItems=\"center\">\n\t\t\t<Stack.Item fill>\n\t\t\t\t<Stack alignItems=\"center\" spacing=\"1\">\n\t\t\t\t\t<Icon name=\"person\" />\n\t\t\t\t\t<BodyText>Anna Smith</BodyText>\n\t\t\t\t</Stack>\n\t\t\t</Stack.Item>\n\t\t\t<BodyText size=\"small\">03:01</BodyText>\n\t\t</Stack>\n\t</Tab>\n</TabGroup>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Stack"), " should:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Be used for small-scale layout tasks when you want a row of components that should wrap on small screen widths"), "\n", React.createElement(_components.li, null, "Be used to vertically center two elements"), "\n", React.createElement(_components.li, null, "Not be used for complex or unique arrangements of components"), "\n", React.createElement(_components.li, null, "Not be used for large-scale page layout"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { Stack } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
